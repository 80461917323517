import { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileText, faRobot } from "@fortawesome/free-solid-svg-icons";
import { CollapsePanel, PaginationITP } from "../index";
import { fetchCallHistory } from "./services";
import Skeleton from "react-loading-skeleton";
import getQueryString from "../../utils/getQueryString";

const ContactHistory = ({ contactId, setFinishFetchCallHistory, loading, myExtension }) => {
  const firstLoad = useRef(true);
  const pagination = useRef({ offset: 0, limit: 5 })

  const [data, setData] = useState([]);
  const [arrayHistory, setArrayHistory] = useState([{}, {}, {}, {}, {}])
  const [totalPages, setTotalPages] = useState(1)
  const [currentPage, setCurrentPage] = useState(Math.floor(pagination.current.offset / pagination.current.limit) + 1)
  const [loadingPage, setLoadingPage] = useState(false)

  const petition = async (parameter) => {
    try {
      const { processedHistory, result } = await fetchCallHistory(contactId, myExtension, parameter);
      firstLoad.current = false;
      const allPages = result?.total_pages || 1
      setTotalPages(allPages)
      setData(processedHistory);
      setFinishFetchCallHistory(true);
      setLoadingPage(false);
    } catch (error) {
      console.log(error);
      setLoadingPage(false);
      setData([]);
    }
  };

  const handleChangePage = (page) => {
    if (loading || loadingPage) return;

    const newOffset = (page - 1) * pagination.current.limit

    if (!loadingPage) {
      setLoadingPage(true)
      setArrayHistory([{}, {}, {}, {}, {}])
      setCurrentPage(page)

      const paginationTemp = { offset: newOffset, limit: pagination.current.limit }
      const query = getQueryString(paginationTemp)
      petition(query)
    }
  }

  useEffect(() => {
    if (!loading && !loadingPage) setArrayHistory(data);
  }, [loading, data, loadingPage]);


  useEffect(() => {
    const query = getQueryString(pagination.current)
    if (contactId && firstLoad.current) petition(query);
  }, [contactId]);

  return (
    <>
      <CollapsePanel
        title="Contact History"
        subtitle="View the history related to this specific contact"
        className="mb-3"
        headerClassName="itp-note-panel"
        colStyle={{ padding: "0" }}
        colStyleContent={{ padding: "0" }}
        xxl={12}
        m0
      >
        {arrayHistory.map((element, i) => (
          <div key={element?.pk || i} className="itp-note-content">
            <div className="itp-note-content__title">
              <div className={`title-note-left-section ${(loading || loadingPage) ? "d-flex align-items-center gap-1" : ""}`}>
                {(loading || loadingPage) ? (
                  <>
                    <Skeleton height={10} width="90px" />
                  </>
                ) : (
                  <>
                    <span className="itp-note-text">
                      {element?.formatedCreatedAt}
                    </span>
                    {element?.was_ai && (
                      <FontAwesomeIcon icon={faRobot} />
                    )}
                  </>
                )}
              </div>
            </div>

            <div className="itp-note-content__content">
              {!loading && !loadingPage && (
                <FontAwesomeIcon
                  icon={faFileText}
                  className="icon-text-note"
                />
              )}

              {(loading || loadingPage) ? (
                <Skeleton height={60} width="250px" />
              ) : (
                <p className="itp-contact-details-p">{element.reason}</p>
              )}
            </div>
          </div>
        ))}

        <PaginationITP 
          pages={totalPages}
          active={currentPage}
          setActive={(page) => handleChangePage(page)}
          loading={loading}
        />
      </CollapsePanel>
    </>
  );
};

export default ContactHistory;
