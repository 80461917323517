import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Card, CardBody, Col, Row } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faTrash, faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { ColumnsOfDraggableCards, MenuRight, ChartLine, LeadDetails } from "../../../../components";
import { useDynamicFunction } from "../../../../contexts/DynamicFunctionContext";
import { fetchFunnelDetails, fetchLeadDetails } from "../services";
import { getDefaultDate } from "../utils";
import { myExtensionPetition } from "../../../../services/myExtension.service";
import userImage from "../../../../assets/images/userImage.png";
import petitionGet from "../../../../services/petitionGet";
import petitionPatch from "../../../../services/petitionPatch";
import ModalDelete from "../ModalUtility/ModalDelete";
import petitionDelete from "../../../../services/petitionDelete";
import Skeleton from "react-loading-skeleton";
import FunelData from "./FunelData/FunelData";
import "../Funnels.css";
import UserAvatar from "../../../../components/UserAvatar/UserAvatar";

const FunnelDetails = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const pk = searchParams.get('pk');
  const priveLevel = searchParams.get('priv_level');
  const firstname = searchParams.get('firstname');
  const lastname = searchParams.get('lastname');

  const { setDynamicFunction, setIsArrowVisible } = useDynamicFunction();

  const navigate = useNavigate()

  const filterDate = useRef("");
  const myExtension = useRef({})

  const [funnelDetails, setFunnelDetails] = useState({})
  const [loading, setLoading] = useState(true);
  const [loadingMenu, setLoadingMenu] = useState(false);
  const [dataFunnel, setDataFunnel] = useState(null);
  const [leads, setLeads] = useState([]);
  const [loadingDragEnd, setLoadingDragEnd] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [currentStartDate, setCurrentStartDate] = useState("");
  const [currentEndDate, setCurrentEndDate] = useState("");
  const [openMenu, setOpenMenu] = useState(false);
  const [loadingLead, setLoadingLead] = useState(false);
  const [leadDetails, setLeadDetails] = useState(null);
  const [modalDelete, setModalDelete] = useState(false);
  const [xLabel, setXLabel] = useState([]);
  const [yLabel, setYLabel] = useState([]);
  const [errorEditContact, setErrorEditContact] = useState("")

  const compareStagesPosition = (a, b) => {
    if (a.stage_position < b.stage_position) {
      return -1;
    }
    if (a.stage_position > b.stage_position) {
      return 1;
    }
    return 0;
  };

  const compareCardPosition = (a, b) => {
    if (a.card_position < b.card_position) {
      return -1;
    }
    if (a.card_position > b.card_position) {
      return 1;
    }
    return 0;
  };

  const closeMenu = () => {
    setErrorEditContact("")
    setModalDelete(false);
    setLeadDetails(null);
    setOpenMenu(false);
  };

  const updateLeadAfterDraggable = (stage_id, data) => {
    setLoadingDragEnd(true);

    const patchPromises = data.map((element) => {
      if (element.card_position !== element.newPosition || element.newItem) {
        let data = {
          funnel_pipeline_stage_id: stage_id,
          card_position: element.newPosition,
        };
        return petitionPatch("leads", { data, lead_id: element.pk });
      } else {
        return Promise.resolve();
      }
    });

    Promise.all(patchPromises)
      .then(() => {
        petition(false, true, funnelDetails);
      })
      .catch((error) => console.log(error));
  };

  const activeLeadDetails = async (e) => {
    await fetchLeadDetails(e, setOpenMenu, setLeadDetails, setLoadingMenu)
  };

  const activeDeleteLead = (e) => {
    setLeadDetails(e);
    setModalDelete(true);
  };

  const applyFilter = () => {
    let parameter = `?added_after=${currentStartDate}&added_before=${currentEndDate}`;
    petition(parameter, false, funnelDetails);
    filterDate.current = parameter;
    setStartDate(currentStartDate);
    setEndDate(currentEndDate);
  };

  const reloadData = () => {
    closeMenu();
    petition(filterDate.current, false, funnelDetails);
  };

  const editLead = (contact, lead) => {
    setLoadingLead(true);
    petitionPatch("leads", {
      lead_id: leadDetails.pk,
      data: lead,
    })
      .then(({ data: result }) => {
        editContact(contact);
      })
      .catch((error) => console.log(error));
  };

  const editContact = (form) => {
    setErrorEditContact("")
    setLoadingLead(true);
    petitionPatch("contact", { data: form, contact_id: leadDetails.contact.pk })
      .then(({ data: result }) => {
        setLoadingLead(false);
        reloadData(false);
      })
      .catch((error) => {
        console.log(error);

        if (error?.response?.status === 400) {
          const messageError = error?.response?.data?.error?.error_message
          setErrorEditContact(messageError)
        }

        setLoadingLead(false);
      });
  };

  const deleteLead = () => {
    setLoadingLead(true);
    petitionDelete("lead", { lead_id: leadDetails.pk })
      .then(({ data: result }) => {
        setLoadingLead(false);
        reloadData();
      })
      .catch((error) => console.log(error));
  };

  
  const handleClickContact = (data) => {
    if (data?.contact?.pk) {
      navigate(`/Contacts?${data?.contact?.pk}`)
    }
  }

  const petition = async (parameter, noLoading, funnelData) => {
    if (!noLoading) setLoading(true);

    let allIdContacts = [];
    let objectTags = {};

    funnelData.stages.forEach((stage) => {
      stage.leads.forEach((lead) => {
        if (lead?.contact?.pk && !allIdContacts.includes(lead?.contact?.pk)) {
          allIdContacts.push(lead?.contact?.pk);
        }
      });
    });

    allIdContacts.forEach(async (element) => {
      await petitionGet("contacts", { parameter: `/${element}` })
        .then(({ data: result }) => {
          objectTags[element] = result.result.tags.map((tag) => tag.tag.name);
        })
        .catch((error) => console.log(error));
    });

    await petitionGet("dashboardFunnel", {
      funnel_id: pk,
      parameter,
    })
      .then(({ data: result }) => {
        result.result.dashboard_info.stages.sort(compareStagesPosition);

        result.result.dashboard_info.stages.forEach((stage) => {
          let totalAmount = 0;
          stage.leads.forEach((lead) => {
            totalAmount = totalAmount + lead.amount;
          });

          stage.total_amount = totalAmount;
          stage.total_leads = stage.leads.length;
        });

        let initialData = {
          columns: {},
        };

        const returnItems = (data) => {
          let number = Math.floor(Math.random() * 1000);
          let item = {
            ...data,
            id: `item-${number + 1}${data.pk}`,
            image: (
              <UserAvatar
                firstname={data?.contact?.firstname}
                lastname={data?.contact?.lastname}
                tagColor="#437097"
                contacts={true}
                minHeight={true}
                style={{ width: '34px', height: '34px', fontSize: '0.9rem' }}
              />
            ),
            title:
              data?.contact?.firstname + " " + (data?.contact?.lastname || ""),
            subtitle: data.name || "user that is assigned to a lead",
            description: data.description || "",
            labelFooter: `$${data.amount}${data.amount < 10 ? ".0" : ".00"}`,
            tags: objectTags[data?.contact?.pk],
            date: data.date_modified,
            titleClick: handleClickContact,
            actions: [
              {
                name: "Edit",
                icon: <FontAwesomeIcon icon={faPenToSquare} />,
                handleClick: activeLeadDetails,
              },
              {
                name: "Delete",
                icon: <FontAwesomeIcon icon={faTrash} />,
                handleClick: activeDeleteLead,
              },
            ],
          };

          return item;
        };

        result.result.dashboard_info.stages.forEach((stage) => {
          let orgStage = {
            ...stage,
            dataRows: stage.leads.sort(compareCardPosition),
          };
          let number = Math.floor(Math.random() * 1000);
          initialData.columns[`column-${number + 1}${stage.pk}`] = {
            id: `column-${number + 1}${stage.pk}`,
            title: stage.name,
            subtitle: `leads - ${stage.total_leads}&Earning : $${
              stage.total_amount
            }${stage.total_amount < 10 ? ".0" : ".00"}`,
            items: stage.leads.sort(compareCardPosition).map((lead) => returnItems(lead)),
            info: orgStage,
          };
          stage.label = stage.name;
          stage.value = stage.pk;
        });

        const dateLabels = Object.keys(result.result.dashboard_by_days).map(
          (element) => {
            const month = element.split("-")[1];
            const day = element.split("-")[2];
            const year = element.split("-")[0];

            return `${month}-${day}-${year}`;
          }
        );

        const valueDateLabels = Object.keys(
          result.result.dashboard_by_days
        ).map((element) => {
          return result?.result?.dashboard_by_days[element];
        });

        setXLabel(dateLabels);
        setYLabel(valueDateLabels);
        setDataFunnel(result.result.dashboard_info);
        setLeads(initialData);
        setLoading(false);
        setLoadingDragEnd(false);
      })
      .catch((error) => console.log(error));
  };

  const getData = async () => {
    try {
      const FunnelData = await fetchFunnelDetails(pk)
      setFunnelDetails(FunnelData)

      getDefaultDate(setStartDate, setEndDate, setCurrentStartDate, setCurrentEndDate)

      setDynamicFunction(() => () => {
        navigate("/Funnels")
      });
      setIsArrowVisible(true);
          
      await petition(false, false, FunnelData);
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (!pk || !priveLevel || !firstname || !lastname) {
      navigate("/Funnels")
    } else {
      const myExtensionData = async () => {
        try {
          const data = await myExtensionPetition();
      
          if (data && Object.keys(data).length > 0) {
            myExtension.current = data;
            getData()
          }
        } catch (error) {
          console.log(error);
        }
      }

      myExtensionData()
    }
  }, []);

  return (
    <>
      {modalDelete && (
        <ModalDelete
          modal={modalDelete}
          closeModal={closeMenu}
          loading={loadingLead}
          modalFunction={deleteLead}
          text="Lead"
        />
      )}

      <Card style={{ marginTop: "5rem" }} className="itp-funnel-details-card">
        <CardBody>
          <FunelData
            priveLevel={priveLevel}
            loading={loading}
            funnelDetails={funnelDetails}
            pk={pk}
          />

          <Row className="d-flex align-items-center">
            <Col xs={6} sm={5} md={6} lg={6} xl={7} xxl={8}>
              <span className="itp-funel-details-subtitle">
                Details of funnel below
              </span>
            </Col>
            <Col
              className="d-flex justify-content-end flex-column"
              xs={12}
              sm={7}
              md={6}
              lg={6}
              xl={5}
              xxl={4}
            >
              <div className="funnel-details-select-date">
                <span className="name-date-range">Set Date :</span>
                <div className="container-date-range funnel-details">
                  <label className="date-range-label">From:</label>
                  <input
                    type="date"
                    className="date-range-input"
                    name="added_affter"
                    value={currentStartDate}
                    disabled={loading}
                    onChange={(e) => {
                      setCurrentStartDate(e.target.value);
                    }}
                  />
                  <div>
                    <FontAwesomeIcon
                      icon={faArrowRight}
                      className="date-range-separator"
                    />
                  </div>
                  <label className="date-range-label">To:</label>
                  <input
                    type="date"
                    className="date-range-input input-right-date"
                    name="added_before"
                    value={currentEndDate}
                    disabled={loading}
                    onChange={(e) => {
                      setCurrentEndDate(e.target.value);
                    }}
                  />
                </div>
              </div>
              {(startDate !== currentStartDate || endDate !== currentEndDate) &&
                currentStartDate &&
                currentEndDate && (
                  <div className="funel-details-apply-filter">
                    <button onClick={applyFilter} className="btn-primary">
                      Apply
                    </button>
                  </div>
                )}
            </Col>
          </Row>

          <Row className={loading ? "mt-2" : "itp-funnel-details-row-summary"}>
            <Col
              className={loading ? "" : "itp-funnel-details-summary"}
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              xxl={12}
            >
              {loading ? (
                <Skeleton height={120} width="100%" />
              ) : (
                <>
                  <span className="itp-funnel-details-summary-title">
                    Leads Summary
                  </span>
                  <div className="d-flex">
                    <span className="itp-funnel-detaiñ-summary-earnings">
                      Earnings :
                    </span>
                    <span className="itp-funnel-detaiñ-summary-number">
                      {dataFunnel.amount_total} $
                    </span>
                  </div>
                  <div className="d-flex itp-funnel-details-total-leads">
                    <div>
                      <label className="itp-funnel-detaiñ-summary-number">
                        {dataFunnel.total}
                      </label>
                      <br />
                      <label className="itp-funnel-detaiñ-summary-number">
                        Total Leads
                      </label>
                    </div>
                    <div className="itp-funnel-details-separator"></div>
                    <div className="d-flex justify-content-between itp-funnel-details-leads-description">
                      {dataFunnel.stages.map((element, i) => (
                        <div className="d-flex flex-column" key={i}>
                          <label className="itp-funnel-details-lead-data-number">
                            {element.total}
                          </label>
                          <label className="itp-funnel-details-lead-data">
                            {element.name}
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                </>
              )}
            </Col>
          </Row>

          <Row className="itp-funnel-details-row-summary">
            <Col
              className="itp-funnel-details-summary graphic"
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              xxl={12}
            >
              <p>Sales Summary</p>
              <div className="h-100 w-100">
                {loading ? (
                  <Skeleton height={300} width="100%" />
                ) : (
                  <ChartLine
                    xLabel={xLabel}
                    yLabel={yLabel}
                    isCustomized={false}
                    isOneReport={true}
                    name="Sales Summary By Days"
                  />
                )}
              </div>
            </Col>
          </Row>

          <div className="itp-funnel-details-new-section">
            <label className="itp-funnel-details-lead-in-funel-title">
              Leads in Funnel
            </label>
            <br></br>
            <label className="itp-funnel-details-lead-in-funel-subtitle">
              View & edit the lead status of this funnel
            </label>
          </div>

          <ColumnsOfDraggableCards
            loading={loading}
            dataColumns={leads}
            setDataColumns={setLeads}
            textNoData="No Leads Added"
            dragEndFunction={updateLeadAfterDraggable}
            dragDisabled={loadingDragEnd}
          />
        </CardBody>
      </Card>

      <MenuRight
        show={openMenu}
        closeMenu={closeMenu}
        title="Lead Details"
        to="to Leads"
      >
        {openMenu && (
          <LeadDetails
            leadDetails={leadDetails}
            setLeadDetails={setLeadDetails}
            loadingEdit={loadingLead}
            errorEditContact={errorEditContact}
            editLead={editLead}
            myExtension={myExtension.current}
            loadingExternal={loadingMenu}
          />
        )}
      </MenuRight>
    </>
  );
};

export default FunnelDetails;
