import ringbacktone from "../assets/audio/ringbacktone.mp3";
import { setupRemoteMedia, cleanupMedia } from "./media"
import {
  UserAgent,
  Inviter,
  SessionState
} from "sip.js";
import config from "../../../config.json";

// const rinding = new Audio(ringbacktone);
// rinding.loop = true;



function call(rinding, number, sipconfiguration, setCallSessions, agent, callSessions, callAudio, sessionIncall, handleAnswerOnInvite, setCallSessionsFunctionEnd, conference, setConnecting) {

  if (callSessions.length < sipconfiguration.maxCalls) {
    console.log("1111111111111111111111111111111111111111111111")
    console.log(number)
    const target = UserAgent.makeURI(`sip:${number.number}@${sipconfiguration.realm}`);
    const options = {
      sessionDescriptionHandlerOptions: {
        constraints: {
          audio: true,
          video: false // Asumiendo que solo quieres audio
        },
        iceCheckingTimeout: config.iceTimeOut,
        iceGatheringTimeout: config.iceTimeOut,
        iceServers: []
      }
    };
    console.log("iceGatheringTimeout 1", options)
    const inviter = new Inviter(agent, target, options);
    // inviter.onAccept = (inviteResponse) => { console.log("11111111111111111111111w",inviteResponse) }

    inviter
      .invite({
        requestDelegate: {
          onReject: (response) => console.log('HELLO REJECTED', response),
          onAccept: (response) => {
            let callUUID = response.message.getHeader("X-UUID") || "";
            let statusCode = response.message.statusCode;
            console.log('HELLo ACCEPTED', response)
            console.log('HELLo ACCEPTED', callUUID)
            console.log('HELLo ACCEPTED', statusCode)

          },
          onProgress: (response) => console.log('HELLo onProgress', response),
          onRedirect: (response) => console.log('HELLo onRedirect', response),
        },
      }).then(() => console.log('HELLO Invite Then'));


    inviter.stateChange.addListener((state) => {

      console.log(`Sessions Call Session state changed to ${state}`);
      switch (state) {
        case SessionState.Initial:
          console.log("Invitation on call state: " + state);
          break;
        case SessionState.Establishing:
          console.log("Invitation on call state: " + state);
          if (setConnecting) setConnecting(true)
          rinding.play();
          setCallSessions([...callSessions, inviter]);
          handleAnswerOnInvite(inviter, sessionIncall.length - 1, true, conference, number)
          break;
        case SessionState.Established:
          console.log("Invitation on call state: " + state);
          if (setConnecting) setConnecting(false)
          rinding.pause();
          setupRemoteMedia([...callSessions, inviter], callAudio);
          break;
        case SessionState.Terminating:
        // fall through
        case SessionState.Terminated:
          rinding.pause();
          setCallSessionsFunctionEnd(inviter, true)
          
          if (setConnecting) setConnecting(false);

          if (callSessions.length === 0) {
            cleanupMedia(callSessions, callAudio)
          }

          break;
        default:
          throw new Error("Unknown session state.");
      }
    });
  } else {
    console.log("zzzzzzzzzzzzz")
    console.log(callSessions.length)
    console.log(sipconfiguration.maxCalls)
    console.log(number)

  }

}





export default call 