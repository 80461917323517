import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

const Voice360Chat = () => {
  const [searchParams] = useSearchParams();
  const [isLoaded, setIsLoaded] = useState(false); // Estado para saber si ya cargó
  const [currentPhone, setCurrentPhone] = useState("");

  const handleOnLoad = () => {
    setIsLoaded(true)
  }

  const phone = searchParams.get("phone") || ""; // Obtiene el número de la query string

  useEffect(() => {
    // Si cambia el phone, recarga el iframe
    if (phone !== currentPhone) {
      setCurrentPhone(phone);
      setIsLoaded(false);
    }
  }, [phone]);

  return (
    <div>
      <iframe
        src={`/voice360-fe-chat/index.html?phone=${currentPhone}`}
        title="Voice360 Chat"
        loading="lazy" // Forzar carga anticipada
        onLoad={handleOnLoad}
        style={{ 
          width: "100%", 
          height: "calc(100vh - 104px)",
          opacity: isLoaded ? 1 : 0, // Oculta el iframe mientras carga
          transition: "opacity 0.3s ease-in-out", // Transición suave
        }}
      ></iframe>
    </div>
  );
};

export default Voice360Chat;