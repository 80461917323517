import { useEffect, useState } from "react";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LabelInput, LabelSelect, SelectComponent } from "../index";
import LoadingButton from "../LoadingButton/LoadingButton";
import petitionPost from "../../services/petitionPost";
import petitionDelete from "../../services/petitionDelete";
import petitionGet from "../../services/petitionGet";
import ModalError from "./ModalError";

const LeadSource = ({ defaultLeadSource, loading, setForm }) => {
  const [valueLeadSource, setValueLeadSource] = useState(null);
  const [showAddLeadSource, setShowAddLeadSource] = useState(false);
  const [showdeleteLeadSource, setShowdeleteLeadSource] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [newLeadSource, setNewLeadSource] = useState("");
  const [infoToDelete, setInfoToDelete] = useState(null);
  const [modal, setModal] = useState(false);
  const [message, setMessage] = useState("");

  const closeModal = () => {
    setModal(false);
    setMessage("");
  }

  const addLeadSource = async () => {
    if (loadingButton || newLeadSource === "") return;

    try {
      setLoadingButton(true);
      const { data: result } = await petitionPost("leadSource", { data: { name: newLeadSource } });

      setShowAddLeadSource(false);
      setShowdeleteLeadSource(false);
      setLoadingButton(false);
      setForm((prev) => ({ ...prev, lead_source_id: result.result.pk }));
      setValueLeadSource(null);
    } catch (error) {
      console.log(error);

      if (error?.response?.status === 400) {
        const messageError = error?.response?.data?.error?.error_message;
        const message = "User is trying to create a lead source with the same name";
        if (messageError === message) {
          setModal(true);
          setMessage(message);
        }
      }
      
      setLoadingButton(false);
    }
  };

  const deleteLeadSource = () => {
    setLoadingButton(true);
    petitionDelete("leadSource", {
      source_lead_id: infoToDelete?.pk,
    })
      .then(() => {
        setLoadingButton(false);
        back();
        setValueLeadSource(null)
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const loadOptionsSource = (inputValue, callback) => {
    petitionGet("leadSource", { parameter: inputValue ? `?name=${inputValue}` : "?offset=0&limit=10" })
      .then(({ data: result }) => {
        const leadSource = result.result

        leadSource.forEach((element) => {
          if (element.value !== "add") {
            if (element.list_name !== "Default") {
              element.icon = <FontAwesomeIcon icon={faTrash} />;
            }
          }
    
          element.label = element.name
          element.value = element.pk
        });
    
        let existAdd = leadSource.some((objeto) => objeto.value === "add");
    
        if (!existAdd) {
          leadSource.unshift({
            label: "Add New Lead Source",
            value: "add",
          });
        }
    
        callback(leadSource);
      })
      .catch((error) => console.log(error))
  };

  const stopFunction = (e, info) => {
    setShowdeleteLeadSource(true);
    setInfoToDelete(info);
  };

  const back = () => {
    setShowAddLeadSource(false);
    setShowdeleteLeadSource(false);
    setInfoToDelete(null);
  };

  const handleChangeLeadSource = (e) => {
    if (e.value === "add") {
      setShowAddLeadSource(true);
    } else {
      e.target = {
        name: "lead_source_id",
        value: e.pk,
      };

      setForm((prev) => ({ ...prev, lead_source_id: e.pk }))
      setValueLeadSource(e);
    }
  };

  useEffect(() => {
    if (defaultLeadSource && defaultLeadSource.label && defaultLeadSource.value) {
      setValueLeadSource(defaultLeadSource);
    }
  }, [defaultLeadSource])

  return (
    <>
      {modal && <ModalError modal={modal} closeModal={closeModal} message={message} />}

      {!showAddLeadSource && !showdeleteLeadSource && (
        <LabelSelect label="Lead Source" loading={loading}>
          <SelectComponent
            onChange={handleChangeLeadSource}
            defaultValue={valueLeadSource}
            isSearchable={true}
            name="lead_source_id"
            placeholder="Choose lead source"
            includeOption={true}
            functionOption={stopFunction}
            asyncSelectOptions={true}
            loadOptions={loadOptionsSource}
          />
        </LabelSelect>
      )}

      {showAddLeadSource && !showdeleteLeadSource && (
        <>
          <LabelInput
            className="mt-3 mb-3"
            label="Lead Source Name List"
            loading={loading}
          >
            <input
              onChange={(e) => setNewLeadSource(e.target.value)}
              name="lead_source"
              className="form-control-itp itp-label-input__input h-100"
              type="text"
            />
          </LabelInput>
          <div className="d-flex gap-2 mb-3">
            <button
              disabled={loadingButton}
              className="btn-light"
              onClick={back}
            >
              Cancel
            </button>

            <button
              disabled={loadingButton}
              className="btn-primary loading"
              onClick={addLeadSource}
            >
              {loadingButton && <LoadingButton />}
              Add
            </button>
          </div>
        </>
      )}

      {!showAddLeadSource && showdeleteLeadSource && (
        <div className="d-flex flex-column justify-content-center gap-2 mt-3 mb-3">
          <label className="itp-label-input__label">Are you Sure?</label>
          <div className="d-flex gap-2">
            <button
              disabled={loadingButton}
              className="btn-light"
              onClick={back}
            >
              Cancel
            </button>
            <button
              disabled={loadingButton}
              className="btn-primary loading"
              onClick={deleteLeadSource}
            >
              {loadingButton && <LoadingButton />}
              Delete
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default LeadSource;
