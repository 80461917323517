/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faThumbtack, faPenToSquare, faTrash, faCopy, faFileImport } from "@fortawesome/free-solid-svg-icons";
import { MenuRight, Filter, CustomTableMain, BulkActions, SelectCheckbox, ShowFilterButton, AddButton, AlignedRow, SearchBoxFilter, LoadingDots, ContactInfo, ModalTags, ModalContactList, ModalSendToFunnel, AlertNotificactions } from "../../../components";
import { myExtensionPetition } from "../../../services/myExtension.service";
import { addContactService, addLeadSendToFunnelService, addOrRemoveTagsIncontactsService, assignedUserService, changeContactListBatchcontactsService, defaultFiltersService, deleteContactsBatchService, deleteContactService, editContactService, exportContactsService, fetchContacts, pinOrUnpinService, searchContactListService, sendToFunnelService, showColumnsService } from "./services";
import { getLastColor, handleChangeColumns } from "./utils";
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import getQueryString from "../../../utils/getQueryString";
import getValuesInObject from "../../../utils/getValuesInObject";
import AddContact from "./AddContact/AddContact";
import ModalDelete from "./AddContact/ModalUtility/ModalDelete";
import petitionGet from "../../../services/petitionGet";
import petitionPatch from "../../../services/petitionPatch";
import SendToFunnel from "./SendToFunnel/SendToFunnel";
import pin from "../../../assets/icons/pin.svg";
import doc from "../../../assets/icons/document.svg";
import addTag from "../../../assets/icons/addTag.svg";
import removeTag from "../../../assets/icons/removeTag.svg";
import deleteIcon from "../../../assets/icons/deleteIcon.svg";
import funnels from "../../../assets/icons/funnels.svg";
import unpin from "../../../assets/icons/unpin.svg";
import clipboardExport from "../../../assets/icons/clipboardExport.svg";
import profile from "../../../assets/icons/profile.svg";
import axios from "axios";
import ModalAssignToUser from "./ModalAssignToUser/ModalAssignToUser";
import "./Contacts.css";

const Contacts = ({ handleActiveCall }) => {
  const currentContactFilters = useRef("")
  const myExtension = useRef({})
  const objectFilter = useRef();
  const filterInput = useRef("");
  const filterRef = useRef("");
  const firstLoad = useRef(true);
  const customFields = useRef([]);
  const itemsPerPage = useRef("10");
  const filterOrderRef = useRef("")
  const currentCancelToken = useRef(null)
  const pagination = useRef({
    offset: 0,
    limit: 10,
  });

  const navigate = useNavigate();

  const [defaultSelectedOptions, setDefaultSelectedOptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openMenu, setOpenMenu] = useState(false);
  const [showAddContactMenu, setShowAddContactMenu] = useState(false);
  const [contactDetailsMenu, setContactDetailsMenu] = useState(null);
  const [dataTable, setDataTable] = useState({});
  const [loadingMenuButton, setLoadingMenuButton] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);
  const [allResult, setAllResult] = useState(null);
  const [text, setText] = useState("");
  const [dataFilter, setDataFilter] = useState(null);
  const [openFilter, setOpenFilter] = useState(false);
  const [loadingFilter, setLoadingFilter] = useState(false);
  const [importContacts, setImportContacts] = useState(false);
  const [importedContacts, setImportedContacts] = useState(false);
  const [addedContactListImort, setAddedContactListImort] = useState(false);
  const [activePincontact, setActivePincontact] = useState(false);
  const [activeUnpincontact, setActiveUnpincontact] = useState(false);
  const [modalTag, setModalTag] = useState(false);
  const [modalSendToFunnel, setModalSendToFunnel] = useState(false)
  const [modalAssignToUser, setModalAssignToUser] = useState(false)
  const [titleModalTag, setTitleModalTag] = useState("");
  const [params, setParams] = useState(window.location.search);
  const [addedCustomFieldInContactDetails, setAddedCustomFieldInContactDetails] = useState(false);
  const [errorAddContact, setErrorAddContact] = useState("")
  const [valueAddContactList, setValueAddContactList] = useState(null);
  const [optionButtonAddContact, setOptionButtonAddContact] = useState("");
  const [sendContactToFunnel, setSendContactToFunnel] = useState(null)
  const [loadingLead, setLoadingLead] = useState(false);
  const [modalContactList, setModalContactList] = useState(false)
  const [arrNameCustomFields, setArrNameCustomFields] = useState([])
  const [defaultOrderColumn, setDefaultOrderColumn] = useState({ key: "firstname", order: "asc" })
  const [errorEditContact, setErrorEditContact] = useState("")
  const [showExportContacts, setShowExportContacts] = useState(false)
  const [rowsIdWithCheckboxSelected, setRowsIdWithCheckboxSelected] = useState([])
  const [selectedAllRows, setSelectedAllRows] = useState(false)
  const [searchValue, setSearchValue] = useState("")
  const [customFieldsConfig, setCustomFieldsConfig] = useState({})
  const [showDeleteContact, setShowDeleteContact] = useState(false)
  const [showDots, setShowDots] = useState(true)
  const [loadingPagItems, setLoadingPagItems] = useState(false)
  const [showErrorTime, setShowErrorTime] = useState(false)
  const [alertTitle, setAlertTitle] = useState("")
  const [alertType, setAlertType] = useState("")
  const [alertMessage, setAlertMessage] = useState("")
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  const [options, setOptions] = useState([
    { value: "Name", label: "Name" },
    { value: "E-mail", label: "E-mail" },
    { value: "Phone Number", label: "Phone Number" },
    { value: "Date Modified", label: "Date Modified" },
    { value: "Last Activity", label: "Last Activity" },
    { value: "Tags", label: "Tags" },
  ]);

  const [formAddContact, setFormAddContact] = useState({
    firstname: "",
    lastname: "",
    phone: "",
    email: "",
    contact_list_id: "",
  });

  const [columnNames, setColumnNames] = useState({
    firstname: "Name",
    email: "E-mail",
    phone: "Phone Number",
    last_activity_time: "Last Activity",
    date_modified: "Date Modified",
    tags: "Tags",
  })

  const [keyNames, setKeyNames] = useState({
    "Name": "firstname",
    "E-mail": "email",
    "Phone Number": "phone",
    "Last Activity": "last_activity_time",
    "Date Modified": "date_modified",
    "Tags": "tags",
  })

  const selectOrder = {
    asc: "desc",
    desc: "asc"
  }

  const updateAlertVariables = (type, title, message, time) => {
    setAlertType(type)
    setAlertTitle(title)
    setAlertMessage(message)
    setShowErrorTime(time)

    setTimeout(() => {
      setShowErrorTime(false)
      setAlertType("")
      setAlertTitle("")
      setAlertMessage("")
    }, time + 300)
  }

  const redirectToPage = (url) => {
    navigate(url)
  }

  const handleChangeViewColumns = (arrColumns) => {
    handleChangeColumns(setDataTable, arrColumns, showColumns)
  };

  const handleChangeInput = async (e) => {
    try {
      if (loading) return;

      const { value } = e.target
      setSearchValue(value)

      // Cancelar la solicitud anterior si existe
      if (currentCancelToken.current) {
        currentCancelToken.current.cancel();
      }

      // Crear un nuevo CancelToken para la nueva solicitud
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();
      currentCancelToken.current = source;
  
      const parameterInput = value ? `&search_query=${value}` : "";
      const paginationTemp = { offset: 0, limit: pagination.current.limit };
      const parameter = getQueryString(paginationTemp) + filterRef.current + parameterInput + filterOrderRef.current;
  
      const { contacts, result } = await fetchContacts(parameter, myExtension.current, source.token);
      setDataTable((prev) => {
        return {
          ...prev, 
          content: [...contacts]
        }
      })
      setAllResult(result)

      pagination.current = paginationTemp;
      filterInput.current = parameterInput;
    } catch (error) {
      console.log(error)
      if (!axios.isCancel(error)) {
        console.log("isCancel", error);
      }
    }
  };

  const activeContactDetails = (e) => {
    setContactDetailsMenu(e);
    setOpenMenu(true);
  };

  const activeFilterDetails = () => {
    if (loading) return;

    setOpenFilter(true);
    setOpenMenu(true);
  };

  const activeModalDelete = (e) => {
    setShowDeleteContact(true)
    setModalDelete(e);
  };

  const activeAddContact = () => {
    if (loading) return;

    setShowAddContactMenu(true);
    setOpenMenu(true);
  };

  const closeMenu = (reload) => {
    setShowDeleteContact(false)
    setErrorAddContact("")
    setErrorEditContact("")
    setOptionButtonAddContact("")
    setActivePincontact(false)
    setActiveUnpincontact(false)
    setModalSendToFunnel(false)
    setModalAssignToUser(false)
    setModalContactList(false)
    setSendContactToFunnel(null)
    setTitleModalTag("");
    setModalTag(false);
    setImportContacts(false);
    setOpenFilter(false);
    setModalDelete(false);
    setContactDetailsMenu(null);
    setShowAddContactMenu(false);
    setOpenMenu(false);

    if (params) {
      navigate("/Contacts");
      setParams("");
    }

    if (reload) {
      reloadData(false, false);
    }
  };

  const reloadData = (noLoading, pag) => {
    closeMenu(false);

    const paginationTemp = pag || pagination.current
    const parameter = getQueryString(paginationTemp) + filterRef.current + filterInput.current + filterOrderRef.current;

    petition(parameter, noLoading, false, myExtension.current);
    pagination.current = paginationTemp;
  };

  const reloadDataTable = async (noLoading, pag, query, myExtensionTemp, filterActive) => {
    if (!noLoading) setLoading(true)
    closeMenu(false);

    const paginationTemp = pag || pagination.current
    const parameter = query || getQueryString(paginationTemp) + filterRef.current + filterInput.current + filterOrderRef.current;
    const myExtensionData = myExtensionTemp || myExtension.current

    const { contacts, result } = await fetchContacts(parameter, myExtensionData);
    pagination.current = paginationTemp;

    if (filterActive) objectFilter.current = filterActive

    if (myExtensionTemp) {
      myExtension.current = myExtensionTemp

      setDataFilter((prev) => {
        prev.parameters.forEach((element) => {
          if (element.title === "Contact List") {
            element.inputs.forEach((el) => {
              el.inputValue = myExtensionTemp?.crm_filters?.contacts?.contact_list_id
            }) 
          }
        })
  
        return prev
      })
    }

    setDataTable((prev) => {
      return {
        ...prev, 
        content: [...contacts]
      }
    })
      
    setAllResult(result)
    setSelectedAllRows(false)
    setRowsIdWithCheckboxSelected([])
    setLoading(false)
    setLoadingPagItems(false)
  }

  const backAddcontact = () => {
    setImportContacts(false);
    setFormAddContact({
      firstname: "",
      lastname: "",
      phone: "",
      email: "",
      contact_list_id: "",
    });
    setValueAddContactList(null);
    setOptionButtonAddContact("");
  };

  const addContact = async () => {
    await addContactService(myExtension, setLoadingMenuButton, formAddContact, backAddcontact, reloadDataTable, setErrorAddContact)
  };

  const editContact = async (form) => {
    const contact_id = contactDetailsMenu.pk
    await editContactService(setErrorEditContact, setLoadingMenuButton, form, contact_id, reloadDataTable)
  };

  const deleteContact = async () => {
    const contact_id = modalDelete.pk
    await deleteContactService(myExtension, setLoadingMenuButton, contact_id, allResult, pagination, reloadDataTable)
  };

  const changePage = async (offset) => {
    try {
      setLoadingPagItems(true)
      const paginationTemp = { offset: offset, limit: pagination.current.limit  };
  
      await reloadDataTable(true, paginationTemp);
      pagination.current = paginationTemp;
    } catch (error) {
      console.log(error)
    }
  };

  const activeDeleteMultiplecontacts = (totalContacts) => {
    if (loading) return;

    const string = `Are you sure you want to delete ${!selectedAllRows ? rowsIdWithCheckboxSelected.length: totalContacts} contacts`;
    setText(string);
    setModalDelete(true);
  };

  const activePinOrUnpinMultipleContacts = (add, totalContacts) => {
    if (loading) return;

    const string = `Are you sure you want to ${add ? "pin" : "unpin"} ${!selectedAllRows ? rowsIdWithCheckboxSelected.length: totalContacts} contacts`;
    setText(string);

    if (add) {
      setActivePincontact(true);
    } else {
      setActiveUnpincontact(true)
    }

    setModalDelete(true);
  };

  const activeAddOrDeleteTagsInContacts = (title) => {
    if (loading) return;

    setModalTag(true);
    setTitleModalTag(title);
  };

  const activeChangeContactListMultiplecontacts = () => {
    if (loading) return;
    setModalContactList(true);
  }

  const activeSendtoFunnelMultipleContacts = () => {
    if (loading) return;
    setModalSendToFunnel(true)
  }

  const activeExportMultipleContacts = (totalContacts) => {
    if (loading) return;

    if (rowsIdWithCheckboxSelected.length > 0 || selectedAllRows) {
      const string = `Are you sure you want to export ${!selectedAllRows ? rowsIdWithCheckboxSelected.length: totalContacts} contact(s)`;
      setText(string);
      setShowExportContacts(true)
      setModalDelete(true);
    }
  }

  const activeAssignToUserMultipleContacts = () => {
    if (loading) return;

    if (rowsIdWithCheckboxSelected.length > 0 || selectedAllRows) {
      setModalAssignToUser(true)
    }
  }

  const pinOrUnpinContacts = async (pk, addOrRemove, noLoading) => {
    const arrayContacts = pk ? [pk] : rowsIdWithCheckboxSelected
    const isPin = typeof addOrRemove !== "undefined" ? addOrRemove : activePincontact
    const query = !selectedAllRows ? "" : filterRef.current + filterInput.current + filterOrderRef.current;
    const parameter = query[0] === "&" ? `?${query.slice(1)}` : query
    await pinOrUnpinService(parameter, setLoadingMenuButton, selectedAllRows, isPin, arrayContacts, reloadDataTable, noLoading)
  };

  const exportMultipleContacts = async () => {
    await exportContactsService(setLoadingMenuButton, selectedAllRows, rowsIdWithCheckboxSelected, currentContactFilters, closeMenu)
  }

  const addOrDeleteTagsInContacts = async (form) => {
    const query = !selectedAllRows ? "" : filterRef.current + filterInput.current + filterOrderRef.current;
    const parameter = query[0] === "&" ? `?${query.slice(1)}` : query
    await addOrRemoveTagsIncontactsService(parameter, setLoadingMenuButton, selectedAllRows, rowsIdWithCheckboxSelected, reloadDataTable, form)
  };

  const deleteMultipleContacts = async () => {
    try {
      setLoadingMenuButton(true);

      const query = !selectedAllRows ? "" : filterRef.current + filterInput.current + filterOrderRef.current;
      const parameter = query[0] === "&" ? `?${query.slice(1)}` : query
      const paginationTemp = await deleteContactsBatchService(parameter, selectedAllRows, rowsIdWithCheckboxSelected, allResult, pagination)
      const { filterActive, myExtensionTemp } = await defaultFiltersService(myExtension.current)

      setLoadingMenuButton(false);
      closeMenu(false);

      await reloadDataTable(false, paginationTemp, false, myExtensionTemp, filterActive);
      pagination.current = paginationTemp;
    } catch (error) {
      console.log(error)
    }
  };

  const updatContactListMultipleContacts = async (contactList) => {
    const contact_list_id = contactList.pk
    const query = !selectedAllRows ? "" : filterRef.current + filterInput.current + filterOrderRef.current;
    const parameter = query[0] === "&" ? `?${query.slice(1)}` : query
    await changeContactListBatchcontactsService(myExtension, parameter, setLoadingMenuButton, selectedAllRows, contact_list_id, rowsIdWithCheckboxSelected, reloadDataTable)
  }

  const sendToFunnelMultipleContacts = async (form) => {
    const query = !selectedAllRows ? "" : filterRef.current + filterInput.current + filterOrderRef.current;
    const parameter = query[0] === "&" ? `?${query.slice(1)}` : query
    await sendToFunnelService(parameter, setLoadingMenuButton, selectedAllRows, form, rowsIdWithCheckboxSelected, reloadDataTable)
  }

  const assignToUserMultipleContacts = async (user) => {
    const query = !selectedAllRows ? '' : filterRef.current + filterInput.current + filterOrderRef.current;
    const parameter = query[0] === '&' ? `?${query.slice(1)}` : query;
    await assignedUserService(parameter, setLoadingMenuButton, selectedAllRows, user, rowsIdWithCheckboxSelected, reloadDataTable);
  }

  const sendToFunnelFunction = (e) => {
    e.label = `${e?.firstname} ${e?.lastname || ""}`
    e.value = e?.pk

    setSendContactToFunnel(e)
    setOpenMenu(true)
  }

  const showColumns = async (arrayColumns) => {
    await showColumnsService(arrayColumns, myExtension)
  };

  const selectTitleMenu = () => {
    if (showAddContactMenu && !contactDetailsMenu && !openFilter && !sendContactToFunnel) {
      return "Add New Contacts";
    } else if (contactDetailsMenu && !showAddContactMenu && !openFilter && !sendContactToFunnel) {
      return "Contact Details";
    } else if (openFilter && !contactDetailsMenu && !showAddContactMenu && !sendContactToFunnel) {
      return "Add Filters";
    } else if (!openFilter && !contactDetailsMenu && !showAddContactMenu && sendContactToFunnel) {
      return "Send contact to a funnel"
    }
  };

  //Falta por organizar
  const onSaveFunction = (form, formLabels) => {   
    setLoadingFilter(true);

    let filtersActive = getValuesInObject(formLabels, formLabels["Only Show Contacts Assigned to Me"] ? "" : "Only Show Contacts Assigned to Me");
    
    if (filtersActive.added_after && filtersActive.added_before) {
      filtersActive["Contact Added Within"] = `${filtersActive.added_after.replaceAll("-", "/")}-${filtersActive.added_before.replaceAll("-", "/")}`
      delete filtersActive.added_after
      delete filtersActive.added_before
    }

    let paginationTemp = {
      limit: pagination.current.limit,
      offset: 0,
    };

    if (!form.owner_id) {
      delete form.owner_id;
    } else {
      form.owner_id = myExtension.current.pk;
    }

    let filterModal = getQueryString(form).replaceAll("?", "&");
    let parameter = getQueryString(paginationTemp) + filterModal + filterInput.current + filterOrderRef.current;

    let filterCustomFields = [];

    customFields.current.forEach((element) => {
      if (
        element.name !== "stuffed" &&
        element.field_type &&
        element.field_metadata
      ) {
        if (element.field_type === "list") {
          let values = [];
          element.field_metadata.list_values.forEach((value) => {
            values.push({
              label: value,
              value: value,
            });
          });
          filterCustomFields.push({
            label: element.name,
            input: "select",
            inputValue: form[`custom_${element.name}`] || "",
            checkbox: true,
            checkboxValue: form[`custom_${element.name}`] ? true : false,
            checkboxName: `checkbox_${element.name}`,
            options: values,
            key: `custom_${element.name}`,
          });
        } else if (element.field_type === "checkbox") {
          filterCustomFields.push({
            label: element.name,
            input: "checkbox",
            inputValue: form[`custom_${element.name}`] ? true : false,
            checkbox: true,
            checkboxValue:
              typeof form[`custom_${element.name}`] === "boolean"
                ? true
                : false,
            checkboxName: `checkbox_${element.name}`,
            key: `custom_${element.name}`,
          });
        } else if (element.field_type === "date") {
          filterCustomFields.push({
            label: element.name,
            input: "singleDate",
            inputValue: form[`custom_${element.name}`] || "",
            checkbox: true,
            checkboxValue: form[`custom_${element.name}`] ? true : false,
            checkboxName: `checkbox_${element.name}`,
            key: `custom_${element.name}`,
          });
        } else {
          filterCustomFields.push({
            label: element.name,
            input: "text",
            inputValue: form[`custom_${element.name}`] || "",
            checkbox: true,
            checkboxValue: form[`custom_${element.name}`] ? true : false,
            checkboxName: `checkbox_${element.name}`,
            key: `custom_${element.name}`,
          });
        }
      }
    });

    petitionPatch("userDetails", {
      data: {
        crm_filters: {
          contacts: form,
        },
      },
    })
      .then(({ data: result }) => {
        const newUserDetails = {
          ...myExtension.current,
          ...result.result,
        };

        myExtension.current = newUserDetails

        setLoadingFilter(false);
        closeMenu(false);
        firstLoad.current = false;
        setDataFilter({
          onSaveFunction: (form, formLabels) => onSaveFunction(form, formLabels),
          onHidde: () => console.log("hidde"),
          parameters: [
            {
              title: "Date",
              inputs: [
                {
                  t: "Contact Added Within",
                  input: "date",
                  inputValue_added_after: form.added_after || "",
                  inputValue_added_before: form.added_before || "",
                  checkbox: true,
                  checkboxValue:
                    form.added_after && form.added_before ? true : false,
                  checkboxName: "checkbox_date",
                  key_added_after: "added_after",
                  key_added_before: "added_before",
                },
              ],
            },
            {
              title: "Assigned User",
              inputs: [
                {
                  label: "Only Show Contacts Assigned to Me",
                  input: "switch",
                  inputValue: form.owner_id ? true : false,
                  key: "owner_id",
                },
              ],
            },
            {
              title: "Custom Fields",
              inputs: filterCustomFields,
            },
            {
              title: "Tags",
              inputs: [
                {
                  label: "Tags Added to a Contact",
                  input: "tags",
                  inputValue: form.tags || [],
                  checkbox: true,
                  checkboxValue: form.tags ? true : false,
                  checkboxName: `checkbox_tags`,
                  key: "tags",
                },
              ],
            },
            {
              title: "Contact List",
              inputs: [
                {
                  label: "Contact List",
                  input: "asyncOptions",
                  inputValue: form.contact_list_id || [],
                  checkbox: true,
                  checkboxValue: form.contact_list_id ? true : false,
                  checkboxName: `checkbox_contact_list`,
                  loadOptions: searchContactListService,
                  key: "contact_list_id",
                },
              ],
            },
            {
              title: "DND SMS",
              inputs: [
                {
                  label: "DND SMS",
                  input: "checkbox",
                  inputValue: form?.dnd_sms ? true : false,
                  checkbox: true,
                  checkboxValue:
                    typeof form?.dnd_sms === "boolean"
                      ? true
                      : false,
                  checkboxName: "checkbox_dnd_sms",
                  key: "dnd_sms",
                },
              ],
            },
          ],
        });

        petition(parameter, false, false, newUserDetails);
        objectFilter.current = filtersActive
        pagination.current = paginationTemp;
        filterRef.current = filterModal;
      })
      .catch((error) => console.log(error));
  };

  const activeItemsPerPage = async (data) => {
    try {
      setLoadingPagItems(true)
      const paginationTemp = { offset: 0, limit: parseInt(data) };
        
      await reloadDataTable(true, paginationTemp);

      itemsPerPage.current = data;
      pagination.current = paginationTemp;
    } catch (error) {
      console.log(error)
    }
  };

  const modalDeleteFunction = () => {
    if (!text && !activePincontact && !activeUnpincontact && !showExportContacts && showDeleteContact) deleteContact();
    if (!activePincontact && !activeUnpincontact && !showExportContacts && !showDeleteContact) deleteMultipleContacts();
    if ((activePincontact || activeUnpincontact) && !showExportContacts && !showDeleteContact) pinOrUnpinContacts();
    if (!activePincontact && !activeUnpincontact && showExportContacts && !showDeleteContact) exportMultipleContacts()
  };

  const handleCopyNumber = (number) => {
    navigator.clipboard
      .writeText(number)
      .then(() => {})
      .catch((error) => {
        console.error(error);
      });
  };

  const onClickPhone = (element) => {
    handleActiveCall(true, element.phone);
  };

  const addLeadWhenClickingSendToFunnel = async (form) => {
    await addLeadSendToFunnelService(setLoadingLead, form, allResult, pagination, reloadDataTable)
  }

  const changeColumnOrder = async (name) => {
    const defaultOrderColumnCopy = defaultOrderColumn

    try {
      if (!arrNameCustomFields.includes(name)) {
        let parameterOrder = ""
    
        if (keyNames[name] === defaultOrderColumn.key) {
          parameterOrder = `&sort_by=${keyNames[name]}&order_by=${selectOrder[defaultOrderColumn.order]}`
          setDefaultOrderColumn({ key: keyNames[name], order: selectOrder[defaultOrderColumn.order] })
          forceUpdate()
        } else {
          parameterOrder = `&sort_by=${keyNames[name]}&order_by=asc`
          setDefaultOrderColumn({ key: keyNames[name], order: "asc" })
          forceUpdate()
        }
          
        const parameter = getQueryString(pagination.current) + filterRef.current + filterInput.current + parameterOrder;
        const { contacts, result } = await fetchContacts(parameter, myExtension.current)

        filterOrderRef.current = parameterOrder
        setDataTable((prev) => {
          return {
            ...prev, 
            content: [...contacts]
          }
        })
        setAllResult(result)
      }
    } catch (error) {
      console.log(error)
      setDefaultOrderColumn(defaultOrderColumnCopy)
      forceUpdate()
    }
  }

  const selectTitleModal = () => {
    if (activePincontact) {
      return "Pin Contact"
    } else if (activeUnpincontact) {
      return "Unpin Contact"
    } else if (showExportContacts) {
      return "Export Contacts"
    } else {
      return false
    }
  }

  const petition = async (parameter, noLoading, parameterContact, myExtensionTemp) => {
    if (!noLoading) setLoading(true);

    currentContactFilters.current = parameter

    let customFieldsColumns = [];
    let customFieldsTemp = [];
    let defaultColumnsSelected = [];
    let columns = []
    const noViewColumns = myExtensionTemp?.contacts_settings_display_columns?.columns || [];

    if (firstLoad.current && myExtensionTemp?.crm_filters?.contacts) {

      if (myExtensionTemp?.crm_filters?.contacts?.owner_id) {
        myExtensionTemp.crm_filters.contacts.owner_id = myExtensionTemp.pk;
      }

      const filterModal = getQueryString(myExtensionTemp.crm_filters.contacts).replaceAll("?", "&");

      parameter = parameter + filterModal + filterInput.current;
      currentContactFilters.current = parameter
      filterRef.current = filterModal;
    }

    options.forEach((element) => {
      if (!noViewColumns.includes(element?.label)) {
        defaultColumnsSelected.push(element?.label);
      }
    });

    await petitionGet("customFields")
    .then(({ data: result }) => {
      let names = options;
      let columnNamesOptions = columnNames
      let keyNameOptions = keyNames
      let nameCustomFieldsArr = []
      let customFieldsConfigTemp = customFieldsConfig

      result.result.forEach((element) => {
        element.label = element.name;
        element.value = element.pk;

        columnNamesOptions[element.name] = element.name
        keyNameOptions[element.name] = element.name

        if (!noViewColumns.includes(element.name)) {
          defaultColumnsSelected.push(element.name);
        }

        if (firstLoad.current || addedCustomFieldInContactDetails) {
          names.push({ label: element.name, value: element.name });
        }

        if (element.field_type === "checkbox") {
          customFieldsConfigTemp[element.name] = { type: "check" }
        }

        customFieldsColumns.push({
          name: element.name,
          key: element.name,
          viewColumn: noViewColumns.includes(element.name) ? false : true,
        });

        nameCustomFieldsArr.push(element.name)
      });

      columns = [
        {
          name: "Name",
          key: "contactName",
          viewColumn: noViewColumns.includes("Name") ? false : true,
        },
        {
          name: "E-mail",
          key: "email",
          viewColumn: noViewColumns.includes("E-mail") ? false : true,
        },
        {
          name: "Phone Number",
          key: "formatedPhone",
          viewColumn: noViewColumns.includes("Phone Number") ? false : true,
        },
        {
          name: "Last Activity",
          key: "last_activity_type",
          viewColumn: noViewColumns.includes("Last Activity") ? false : true,
        },
        {
          name: "Date Modified",
          key: "date_modified",
          viewColumn: noViewColumns.includes("Date Modified") ? false : true,
        },
        {
          name: "Tags",
          key: "tags",
          viewColumn: noViewColumns.includes("Tags") ? false : true,
        },
        ...customFieldsColumns,
      ];

      customFieldsTemp = result.result;
      setDefaultSelectedOptions(defaultColumnsSelected);
      setOptions(names);
      setColumnNames(columnNamesOptions)
      setKeyNames(keyNameOptions)
      setArrNameCustomFields(nameCustomFieldsArr)
      setCustomFieldsConfig(customFieldsConfigTemp)
      customFields.current = result.result;

      setDataTable({ columns, actions: { title: "Actions", content: [] } })
      setShowDots(false)
      setLoading(true)
    })
    .catch((error) => console.log(error));

    if (!parameterContact) {

      try {
        const { contacts, result } = await fetchContacts(parameter, myExtensionTemp);
        setAllResult(result)

        if (!myExtensionTemp?.crm_filters?.contacts) {
        let filterCustomFields = [];
        customFieldsTemp.forEach((element) => {
          if (
            element.name !== "stuffed" &&
            element.field_type &&
            element.field_metadata
          ) {
            if (element.field_type === "list") {
              let values = [];
              element?.field_metadata?.list_values.forEach((value) => {
                values.push({
                  label: value,
                  value: value,
                });
              });
              filterCustomFields.push({
                label: element.name,
                input: "select",
                inputValue: "one",
                checkbox: true,
                checkboxValue: false,
                checkboxName: `checkbox_${element.name}`,
                options: values,
                key: `custom_${element.name}`,
              });
            } else if (element.field_type === "checkbox") {
              filterCustomFields.push({
                label: element.name,
                input: "checkbox",
                inputValue: false,
                checkbox: true,
                checkboxValue: false,
                checkboxName: `checkbox_${element.name}`,
                key: `custom_${element.name}`,
              });
            } else if (element.field_type === "date") {
              filterCustomFields.push({
                label: element.name,
                input: "singleDate",
                inputValue: "",
                checkbox: true,
                checkboxValue: false,
                checkboxName: `checkbox_${element.name}`,
                key: `custom_${element.name}`,
              });
            } else {
              filterCustomFields.push({
                label: element.name,
                input: "text",
                inputValue: "",
                checkbox: true,
                checkboxValue: false,
                checkboxName: `checkbox_${element.name}`,
                key: `custom_${element.name}`,
              });
            }
          }
        });

        setDataFilter({
          onSaveFunction: (form, formLabels) => onSaveFunction(form, formLabels),
          onHidde: () => console.log("hidde"),
          parameters: [
            {
              title: "Date",
              inputs: [
                {
                  label: "Contact Added Within",
                  input: "date",
                  inputValue_added_after: "",
                  inputValue_added_before: "",
                  checkbox: true,
                  checkboxValue: false,
                  checkboxName: "checkbox_date",
                  key_added_after: "added_after",
                  key_added_before: "added_before",
                },
              ],
            },
            {
              title: "Assigned User",
              inputs: [
                {
                  label: "Only Show Contacts Assigned to Me",
                  input: "switch",
                  inputValue: false,
                  key: "owner_id",
                },
              ],
            },
            {
              title: "Custom Fields",
              inputs: filterCustomFields,
            },
            {
              title: "Tags",
              inputs: [
                {
                  label: "Tags Added to a Contact",
                  input: "tags",
                  inputValue: [],
                  checkbox: true,
                  checkboxValue: false,
                  checkboxName: `checkbox_tags`,
                  key: "tags",
                },
              ],
            },
            {
              title: "Contact List",
              inputs: [
                {
                  label: "Contact List",
                  input: "asyncOptions",
                  inputValue: [],
                  checkbox: true,
                  checkboxValue: false,
                  checkboxName: `checkbox_contact_list`,
                  loadOptions: searchContactListService,
                  key: "contact_list_id",
                  asyncOptions: true,
                },
              ],
            },
            {
              title: "DND SMS",
              inputs: [
                {
                  label: "DND SMS",
                  input: "checkbox",
                  inputValue: false,
                  checkbox: true,
                  checkboxValue: false,
                  checkboxName: "checkbox_dnd_sms",
                  key: "dnd_sms",
                },
              ],
            },
          ],
        });
        } else if (myExtensionTemp.crm_filters.contacts) {
        let filterCustomFields = [];

        customFieldsTemp.forEach((element) => {
          if (
            element.name !== "stuffed" &&
            element.field_type &&
            element.field_metadata
          ) {
            if (element.field_type === "list") {
              let values = [];
              element.field_metadata.list_values.forEach((value) => {
                values.push({
                  label: value,
                  value: value,
                });
              });
              filterCustomFields.push({
                label: element.name,
                input: "select",
                inputValue:
                  myExtensionTemp?.crm_filters?.contacts[
                    `custom_${element.name}`
                  ] || "",
                checkbox: true,
                checkboxValue: myExtensionTemp.crm_filters.contacts[
                  `custom_${element.name}`
                ]
                  ? true
                  : false,
                checkboxName: `checkbox_${element.name}`,
                options: values,
                key: `custom_${element.name}`,
              });
            } else if (element.field_type === "checkbox") {
              filterCustomFields.push({
                label: element.name,
                input: "checkbox",
                inputValue: myExtensionTemp?.crm_filters?.contacts[
                  `custom_${element.name}`
                ]
                  ? true
                  : false,
                checkbox: true,
                checkboxValue:
                  typeof myExtensionTemp?.crm_filters?.contacts[
                    `custom_${element.name}`
                  ] === "boolean"
                    ? true
                    : false,
                checkboxName: `checkbox_${element.name}`,
                key: `custom_${element.name}`,
              });
            } else if (element.field_type === "date") {
              filterCustomFields.push({
                label: element.name,
                input: "singleDate",
                inputValue: myExtensionTemp?.crm_filters?.contacts[
                  `custom_${element.name}`
                ] || "",
                checkbox: true,
                checkboxValue: myExtensionTemp.crm_filters.contacts[
                  `custom_${element.name}`
                ]
                  ? true
                  : false,
                checkboxName: `checkbox_${element.name}`,
                key: `custom_${element.name}`,
              });
            } else {
              filterCustomFields.push({
                label: element.name,
                input: "text",
                inputValue:
                  myExtensionTemp?.crm_filters?.contacts[
                    `custom_${element.name}`
                  ] || "",
                checkbox: true,
                checkboxValue: myExtensionTemp.crm_filters.contacts[
                  `custom_${element.name}`
                ]
                  ? true
                  : false,
                checkboxName: `checkbox_${element.name}`,
                key: `custom_${element.name}`,
              });
            }
          }
        });

        setDataFilter({
          onSaveFunction: (form, formLabels) => onSaveFunction(form, formLabels),
          onHidde: () => console.log("hidde"),
          parameters: [
            {
              title: "Date",
              inputs: [
                {
                  label: "Contact Added Within",
                  input: "date",
                  inputValue_added_after:
                    myExtensionTemp?.crm_filters?.contacts?.added_after ||
                    "",
                  inputValue_added_before:
                    myExtensionTemp?.crm_filters?.contacts?.added_before ||
                    "",
                  checkbox: true,
                  checkboxValue:
                    myExtensionTemp.crm_filters.contacts.added_after &&
                    myExtensionTemp.crm_filters.contacts.added_before
                      ? true
                      : false,
                  checkboxName: "checkbox_date",
                  key_added_after: "added_after",
                  key_added_before: "added_before",
                },
              ],
            },
            {
              title: "Assigned User",
              inputs: [
                {
                  label: "Only Show Contacts Assigned to Me",
                  input: "switch",
                  inputValue: myExtensionTemp.crm_filters.contacts.owner_id
                    ? true
                    : false,
                  key: "owner_id",
                },
              ],
            },
            {
              title: "Custom Fields",
              inputs: filterCustomFields,
            },
            {
              title: "Tags",
              inputs: [
                {
                  label: "Tags Added to a Contact",
                  input: "tags",
                  inputValue:  myExtensionTemp.crm_filters.contacts.tags || [],
                  checkbox: true,
                  checkboxValue: myExtensionTemp.crm_filters.contacts.tags ? true : false,
                  checkboxName: `checkbox_tags`,
                  key: "tags",
                },
              ],
            },
            {
              title: "Contact List",
              inputs: [
                {
                  label: "Contact List",
                  input: "asyncOptions",
                  inputValue:
                    myExtensionTemp.crm_filters.contacts.contact_list_id ||
                    [],
                  checkbox: true,
                  checkboxValue: myExtensionTemp.crm_filters.contacts
                    .contact_list_id
                    ? true
                    : false,
                  checkboxName: `checkbox_contact_list`,
                  loadOptions: searchContactListService,
                  key: "contact_list_id",
                },
              ],
            },
            {
              title: "DND SMS",
              inputs: [
                {
                  label: "DND SMS",
                  input: "checkbox",
                    inputValue: myExtensionTemp?.crm_filters?.contacts?.dnd_sms
                    ? true
                    : false,
                  checkbox: true,
                  checkboxValue:
                    typeof myExtensionTemp?.crm_filters?.contacts?.dnd_sms === "boolean"
                      ? true
                      : false,
                  checkboxName: "checkbox_dnd_sms",
                  key: "dnd_sms",
                },
              ],
            },
          ],
        });
        }

        const newDataTable = {
          columns,
          content: [...contacts],
          actions: {
            title: "Actions",
            content: [
              {
                name: "Pin Contact",
                handleClick: (e) => pinOrUnpinContacts(e.pk, true, true),
                icon: <FontAwesomeIcon icon={faThumbtack} />,
              },
              {
                name: "Move To Funnel",
                handleClick: sendToFunnelFunction,
                icon: <FontAwesomeIcon icon={faFileImport} />,
              },
              {
                name: "Delete Contact",
                handleClick: activeModalDelete,
                icon: <FontAwesomeIcon icon={faTrash} />,
              },
              {
                name: "Copy Number",
                handleClick: (e) => handleCopyNumber(e.phone || ""),
                icon: <FontAwesomeIcon icon={faCopy} />,
              },
              {
                name: "Edit Details",
                handleClick: activeContactDetails,
                icon: <FontAwesomeIcon icon={faPenToSquare} />,
              },
            ],
          },
        }

        setDataTable(newDataTable);
        setAddedCustomFieldInContactDetails(false);
        setImportedContacts(false);
        setAddedContactListImort(false);
        setSelectedAllRows(false)
        setRowsIdWithCheckboxSelected([])
        firstLoad.current = false;
        setLoading(false);
      } catch (error) {
        console.log(error)
      }

    } else {
      await petitionGet("contacts", { parameter: parameterContact })
        .then(({ data: result }) => {
          result.result.tags = result.result.tags.map((tag) => ({
            ...tag,
            title: tag.tag.name,
            pk: tag.tag_id,
            tag_color: tag.tag.tag_color,
          }));

          activeContactDetails(result.result);
          setLoading(false);
        })
        .catch((error) => console.log(error));
    }
  };

  useEffect(() => {
    const myExtensionData = async () => {
      try {
        const data = await myExtensionPetition()

        if (data && Object.keys(data).length > 0) {
          const { filterActive, myExtensionTemp} = await defaultFiltersService(data)
          objectFilter.current = filterActive

          if (!params) {
            petition(getQueryString(pagination.current), true, false, myExtensionTemp);
          } else {
            let parameterContact = "/" + params.slice(1);
            petition(getQueryString(pagination.current), true, parameterContact, myExtensionTemp);
          }
          myExtension.current = myExtensionTemp
        } 

      } catch(error) {
        console.log(error)
      }
    }

    myExtensionData()
  }, []);

  return (
    <div className="itp-test">
      {showDots ? (
        <LoadingDots />
      ) : (
        <>
          {modalDelete && <ModalDelete modal={modalDelete} closeModal={() => closeMenu(false)} loading={loadingMenuButton} modalFunction={modalDeleteFunction} text={text} title={selectTitleModal()} />}

          {modalTag && <ModalTags modal={modalTag} closeModal={() => closeMenu(false)} loading={loadingMenuButton} modalFunction={addOrDeleteTagsInContacts} title={titleModalTag} />}

          {modalContactList && <ModalContactList modal={modalContactList} closeModal={() => closeMenu(false)} loading={loadingMenuButton} modalFunction={updatContactListMultipleContacts} />}

          {modalSendToFunnel && <ModalSendToFunnel modal={modalSendToFunnel} closeModal={closeMenu} saveFunction={sendToFunnelMultipleContacts} loadingSave={loadingMenuButton} />}

          {modalAssignToUser && <ModalAssignToUser modal={modalAssignToUser} closeModal={closeMenu} loadingSave={loadingMenuButton} modalFunction={assignToUserMultipleContacts} />}

          {!params && (
            <>
              <AlignedRow noCentered m1 className="mb-2 mt-4">
                <SearchBoxFilter placeholder="Search Contacts" handleChange={handleChangeInput} value={searchValue} className="itp-searchBoxFilter-responsive" />
              </AlignedRow>

              <CustomTableMain
                data={dataTable}
                loading={loading}
                loadingPagItems={loadingPagItems}
                clickRow={activeContactDetails}
                isBackend={true}
                paginationData={parseInt(itemsPerPage.current)}
                totalPages={allResult?.total_pages}
                onPageChange={changePage}
                showSelectSingleCheckbox={true}
                onChangeItemsPerPage={activeItemsPerPage}
                offset={pagination.current.offset}
                defaultOrderColumn={defaultOrderColumn}
                columnNames={columnNames}
                changeColumnOrder={changeColumnOrder}
                module="contacts"
                withCheckboxes={true}
                externalSelectedRows={rowsIdWithCheckboxSelected}
                onSelectionChange={(data) => setRowsIdWithCheckboxSelected(data)}
                allRows={allResult?.item_count}
                allowSelectAll={true}
                selectedAllRows={selectedAllRows}
                handleSelectAllClick={() => setSelectedAllRows(!selectedAllRows)}
                noDataMessage={(filterRef.current || searchValue) && !allResult?.result.length ? 'no results found' : ''}
                activeSearch={searchValue ? true : false}
                actionsLeft={[
                  {
                    component: (
                      <BulkActions
                        actions={[
                          {
                            icon: <img src={pin} alt="Pin" />,
                            name: 'Pin contacts',
                            handleClick: () => activePinOrUnpinMultipleContacts(true, allResult?.item_count),
                          },
                          {
                            icon: <img style={{ width: '16px', height: '16px' }} src={unpin} alt="Unpin" />,
                            name: 'Unpin contacts',
                            handleClick: () => activePinOrUnpinMultipleContacts(false, allResult?.item_count),
                          },
                          {
                            icon: <img src={addTag} alt="Add Tag" />,
                            name: 'Add Tags',
                            handleClick: () => activeAddOrDeleteTagsInContacts('Add Tags'),
                          },
                          {
                            icon: <img src={removeTag} alt="Remove Tag" />,
                            name: 'Remove Tags',
                            handleClick: () => activeAddOrDeleteTagsInContacts('Removed Tags'),
                          },
                          {
                            icon: <img src={deleteIcon} alt="Delete Contact" />,
                            name: 'Delete contact',
                            handleClick: () => activeDeleteMultiplecontacts(allResult?.item_count),
                          },
                          {
                            icon: <img style={{ width: '16px', height: '16px' }} src={doc} alt="Document" />,
                            name: 'Move to Contact List',
                            handleClick: activeChangeContactListMultiplecontacts,
                          },
                          {
                            icon: <img style={{ width: '16px', height: '16px' }} src={funnels} alt="Funnel" />,
                            name: 'Move to Funnel',
                            handleClick: activeSendtoFunnelMultipleContacts,
                          },
                          {
                            icon: <img style={{ width: '16px', height: '16px' }} src={clipboardExport} alt="Export" />,
                            name: 'Export Contacts',
                            handleClick: () => activeExportMultipleContacts(allResult?.item_count),
                          },
                          {
                            icon: <img style={{ width: '16px', height: '16px' }} src={profile} alt="Export" />,
                            name: 'Assign to User',
                            handleClick: activeAssignToUserMultipleContacts,
                          },
                        ]}
                      />
                    ),
                  },
                  {
                    component: <SelectCheckbox name="Display Columns" options={options} defaultSelected={defaultSelectedOptions} checkFunction={handleChangeViewColumns} noMarginLeft={true} style={{ marginLeft: '0' }} loading={loading} className="select-checkbox-responsive" />,
                  },
                ]}
                actionsRight={[
                  {
                    component: <ShowFilterButton handleClick={() => redirectToPage('/Contacts/Contact-List')} className="itp-show-filterButton-responsive" name="Contact List" icon={<FormatListBulletedIcon className="tabs-icons" />} />,
                  },
                  {
                    component: <ShowFilterButton handleClick={() => redirectToPage('/Contacts/Tags')} className="itp-show-filterButton-responsive" name="Tags" icon={<LocalOfferIcon className="tabs-icons" />} />,
                  },
                  {
                    component: <ShowFilterButton handleClick={activeFilterDetails} activeFilters={objectFilter.current} className="itp-show-filterButton-responsive" />,
                  },
                  {
                    component: <AddButton name="Add New Contact" handleClick={activeAddContact} className="itp-addButton-responsive" />,
                  },
                ]}
                columnConfig={{
                  Name: {
                    type: 'avatarAndIcon',
                    isAvatar: true,
                    keyFirstname: 'firstname',
                    keyLastname: 'lastname',
                    contacts: true,
                    minHeight: true,
                    keyLastColor: 'tags',
                    getLastColor: getLastColor,
                    icon: true,
                    keyIcon: 'hasIcon',
                    iconFunction: (e) => pinOrUnpinContacts(e.pk, false, true),
                  },
                  'Phone Number': {
                    type: 'phoneNumberLink',
                    onClick: onClickPhone,
                  },
                  'Last Activity': {
                    type: 'lastActivity',
                    keyIcon: 'selectIcon',
                    keyArrow: 'selectArrow',
                    keyDate: 'keyDate',
                  },
                  'Date Modified': {
                    type: 'date',
                    spliter: 'T',
                  },
                  Tags: {
                    type: 'tags',
                  },
                  ...customFieldsConfig,
                }}
              />
            </>
          )}

          <AlertNotificactions type={alertType} alertMessage={alertMessage} showTime={showErrorTime} title={alertTitle} />

          <MenuRight show={openMenu} closeMenu={() => closeMenu(addedCustomFieldInContactDetails || importedContacts || addedContactListImort || params)} title={selectTitleMenu()} to="to Contacts" w50={importContacts}>
            {showAddContactMenu && !contactDetailsMenu && !openFilter && !sendContactToFunnel && <AddContact addFunction={addContact} loadingMenuButton={loadingMenuButton} setImportContacts={setImportContacts} setImportedContacts={setImportedContacts} setAddedContactListImort={setAddedContactListImort} customFields={customFields.current} form={formAddContact} setForm={setFormAddContact} error={errorAddContact} setError={setErrorAddContact} valueContactList={valueAddContactList} setValueContactList={setValueAddContactList} optionButton={optionButtonAddContact} setOptionButton={setOptionButtonAddContact} back={backAddcontact} myExtension={myExtension.current} updateAlertVariables={updateAlertVariables} />}

            {contactDetailsMenu && !showAddContactMenu && !openFilter && !sendContactToFunnel && <ContactInfo contactDetails={contactDetailsMenu} customFields={customFields} editContact={editContact} loadingEdit={loadingMenuButton} myExtension={myExtension.current} setAddedCustomField={setAddedCustomFieldInContactDetails} errorEditContact={errorEditContact} />}

            {openFilter && !contactDetailsMenu && !showAddContactMenu && !sendContactToFunnel && <Filter info={dataFilter} loading={loadingFilter} />}

            {!openFilter && !contactDetailsMenu && !showAddContactMenu && sendContactToFunnel && (
              <>
                <SendToFunnel defaultContact={sendContactToFunnel} loadingLead={loadingLead} addNewLead={addLeadWhenClickingSendToFunnel} />
              </>
            )}
          </MenuRight>
        </>
      )}
    </div>
  );
}

export default Contacts;
